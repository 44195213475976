<template>
  <div>
    <p class="mb-3">
      The first ionization energy of
      <stemble-latex :content="element1" />
      is greater than that of
      <stemble-latex :content="element2" />
      because:
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question200b',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    element1() {
      if (this.versionVariable.value === 1) {
        return '$\\textbf{nitrogen, N,}$';
      } else if (this.versionVariable.value === 2) {
        return '$\\textbf{phosphorus, P,}$';
      } else {
        return '';
      }
    },
    element2() {
      if (this.versionVariable.value === 1) {
        return '$\\textbf{oxygen, O,}$';
      } else if (this.versionVariable.value === 2) {
        return '$\\textbf{sulfur, S,}$';
      } else {
        return '';
      }
    },
    options1() {
      if (this.versionVariable.value === 1) {
        return [
          {
            expression: 'Ionization energy decreases along the period in the periodic table',
            value: '1',
          },
          {
            expression: 'The effective nuclear charge for N is greater than O',
            value: '2',
          },
          {
            expression: 'N has a half-filled p-shell, which is a particularly stable configuration',
            value: '4',
          },
          {
            expression: 'O has a half-filled p-shell, which is a particularly stable configuration',
            value: '5',
          },
        ];
      } else if (this.versionVariable.value === 2) {
        return [
          {
            expression: 'P has a half-filled p-shell, which is a particularly stable configuration',
            value: '4',
          },
          {
            expression: 'S has a half-filled p-shell, which is a particularly stable configuration',
            value: '5',
          },
          {
            expression: 'The effective nuclear charge for P is greater than S',
            value: '2',
          },
          {
            expression: 'Ionization energy decreases along the period in the periodic table',
            value: '1',
          },
        ];
      } else {
        return [];
      }
    },
  },
};
</script>
